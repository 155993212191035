import React, { useState, useEffect } from "react";
import MultiImageSlider from "../common/pageComponents/multiImageSlider";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useHistory } from "react-router-dom";
import allowables from "../../utils/allowables";

const spectate = [
  "spectate", // league homepage, spectated org is passed not user org
  "Fields",
  "Seasons",
  "Divisions",
  "Teams",
  "Players",
  "Current Stats",
  "Archive",
  "My History",
];
const mymatches = ["Upcoming Matches", "Match Review"];
const scorecard = ["Scorecards"];
const local = [
  "Profile",
  "Tactics",
  "Message Center",
  "Code of Conduct",
  "Vaccine",
];

const pageMap = {};
spectate.forEach((page) => {
  pageMap[page] = "spectate";
});
mymatches.forEach((page) => {
  pageMap[page] = "mymatches";
});
scorecard.forEach((page) => {
  pageMap[page] = "scorecard";
});
local.forEach((page) => {
  pageMap[page] = "local";
});

const CustomAdUnit = React.memo(({ page, org, user }) => {
  let history = useHistory();
  const { width } = useWindowDimensions();
  const [adUnits, setAdUnits] = useState([]);

  useEffect(() => {
    let units = [];
    // only show ads to non admins
    if (!user?.role?.includes("admin")) {
      const customAds = org?.customAds;
      const pageName =
        page || allowables.pageHeaderConversion(history?.location?.pathname);

      if (pageName && customAds?.enabled && customAds?.adUnits) {
        units = customAds.adUnits[pageMap[pageName]] || [];
      }
    }
    setAdUnits(units);
  }, [user, org, page, history]);

  const handleClickImage = (index) => {
    if (adUnits[index]?.link) window.open(adUnits[index]?.link, "_blank");
  };

  if (!adUnits?.length) return null;
  return (
    <div data-testid="custom-ad">
      <div style={{ height: Math.min(width, 500) / 5 }} />
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width,
        }}
      >
        <MultiImageSlider
          images={adUnits}
          imageDisplayTime={(org?.customAds?.timeout || 10) * 1000}
          altText="advertisement"
          imageBucket="https://storage.googleapis.com/ultimatescoreboard-customads/"
          imageProperty="imageUrl"
          ratio={1 / 5}
          onClickCallback={handleClickImage}
          imageTransitionTime={0.5}
          maxWidth={Math.min(width, 500)}
        />
      </div>
    </div>
  );
});

export default CustomAdUnit;
