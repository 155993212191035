import React, { useState, useContext } from "react";
import { GiSoccerBall } from "react-icons/gi";
import { FaSquare } from "react-icons/fa";

import MatchPopupSoccer from "./matchPopupSoccer";
import CustomConfirm from "./../../common/customs/customConfirm";
import Penalties from "../penalties";
import { createSoccerEventText } from "../../../utils/soccerFunctions";
import NonFormSelect from "../../common/form/nonFormSelect";
import OrgContext from "../../../context/orgContext";

const SoccerTeamSegment = ({
  match,
  players,
  teamOrder,
  chronoEventsNames,
  onEvent,
  penalties,
  onChangePenalties,
  onSubmit,
  header,
  penaltyVisualization,
}) => {
  const org = useContext(OrgContext);
  const [openModal, setOpenModal] = useState(null);
  const [dialog, setDialog] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [assistPlayerID, setAssistPlayerID] = useState(null);
  const [event, setEvent] = useState("");
  const buttons = [
    {
      name: "Goal",
      id: "Goals",
      Icon: GiSoccerBall,
      buttonColor: "btn-info btn-lg",
      color: "bg-info text-white",
    },
    {
      name: "Yellow Card",
      id: "YellowCards",
      Icon: FaSquare,
      buttonColor: "soccer-yellow-card btn-md",
      color: "soccer-yellow-card-bg",
    },
    {
      name: "Red Card",
      id: "RedCards",
      Icon: FaSquare,
      buttonColor: "soccer-red-card btn-md",
      color: "soccer-red-card-bg",
    },
  ];

  const openConfirm = (selectedPlayer, selectedEvent) => {
    setPlayer(selectedPlayer);
    setEvent(selectedEvent);
    setDialog(createSoccerEventText(selectedPlayer, selectedEvent));
    setConfirmOpen(true);
  };
  const handleEvent = () => {
    const assistPlayer = players[event.slice(0, 4)].find(
      (p) => (p.profileID?._id || p._id) === assistPlayerID
    );
    setOpenModal(null);
    setAssistPlayerID(null);
    onEvent(player, event, assistPlayer);
  };

  return (
    <div>
      <div className="row">
        {teamOrder.map((team) => {
          return (
            <div className="col" key={team}>
              {buttons.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <button
                      className={"btn btn-block " + e.buttonColor}
                      onClick={() => setOpenModal(team + "Team" + e.id)}
                    >
                      <e.Icon /> {e.name}
                    </button>
                    <br />
                    {e.id.includes("Goal") && <br />}
                    <MatchPopupSoccer
                      team={team}
                      players={players[team]}
                      onClick={openConfirm}
                      popupOpen={openModal === team + "Team" + e.id}
                      closeModal={() => setOpenModal(null)}
                      id={team + "Team" + e.id}
                      teamHeader={match[team + "TeamName"]}
                      eventHeader={e.name}
                      color={e.color}
                      chronoEventsNames={chronoEventsNames}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
        <CustomConfirm
          dialog={dialog}
          isOpen={confirmOpen}
          callback={handleEvent}
          close={() => {
            setConfirmOpen(false);
            setAssistPlayerID(null);
          }}
        >
          {org?.misc?.soccerSportOptions?.trackAssists &&
            event?.toLowerCase().includes("goal") && (
              <NonFormSelect
                name="assist"
                label="Assist"
                options={players[event.slice(0, 4)]}
                onChange={(event) => setAssistPlayerID(event.target.value)}
                value={assistPlayerID?._id}
                splitNames
                override_id={(option) => option.profileID?._id || option._id}
                overrideDefaultPlaceholder="None"
              />
            )}
        </CustomConfirm>
      </div>
      <br />
      <Penalties
        match={match}
        penalties={penalties}
        onChangePenalties={onChangePenalties}
        teamOrder={teamOrder}
        onSubmit={onSubmit}
        header={header}
        penaltyVisualization={penaltyVisualization}
      />
    </div>
  );
};

export default SoccerTeamSegment;
