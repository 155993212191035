import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { ToastContainer, toast } from "react-toastify";
import HttpsRedirect from "react-https-redirect";

import "./App.css";
import {
  getCurrentUser,
  acceptCookies,
  revokeCookieAcceptance,
  getUserAccounts,
  switchAccount,
  refreshUser,
} from "./services/userService";
import { getOrg } from "./services/orgService";
import NavBar from "./components/navbar";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKey } from "./utils/paymentsUtil";
import OrgContext from "./context/orgContext";
import HeaderContext from "./context/headerContext";
import LoadingModal from "./components/common/loading/loadingModal";
import allowables from "./utils/allowables";
// import MobileAppDownloadPrompt from "./components/common/pageComponents/mobileAppDownloadPrompt";
import MainSwitchRouter from "./components/mainSwitchRouter";
import ErrorBoundary from "./components/errorBoundary";
import ContactUsModal from "./components/contactPolicies/contactUsModal";
import CookieAcceptancePrompt from "./components/common/pageComponents/cookieAcceptancePrompt";
import { cancelMatchInProgress } from "./utils/liveScoresUtil";
// eslint-disable-next-line no-unused-vars
import DevAlert from "./components/dev/devAlert";
import CustomAdUnit from "./components/ads/customAdUnit";

const stripePromise = loadStripe(stripeKey());
ReactGA.initialize(process.env.REACT_APP_GA || "x", {
  testMode: process.env.NODE_ENV !== "production",
});

class App extends Component {
  state = {
    org: null,
    user: null,
    pageHeader: null,
    showCookieAcceptance: false,
    cookiePromptActive: false,
    cookiesAccepted: false,
    cookiesDeclined: false,
    stickyTopNavBar: true,
    mobileAppPromptOpen: true,
    loading: false,
    progress: [0],
    contactUsOpen: false,
    userAccounts: [],
    allowStickyItems: true,
  };

  async componentDidMount() {
    // add listener for back button to update header
    window.addEventListener("popstate", () => {
      this.updateHeader();
    });

    // get user and look for org
    const user = getCurrentUser();
    if (user) {
      this.setState({ loading: true });
      // refresh the user
      await refreshUser();

      if (user.orgID) await this.refreshOrg();

      // get the profiles for the user
      let profileResponse = await getUserAccounts();
      if (profileResponse?.status === 200)
        this.setState({ userAccounts: profileResponse.data });
      this.setState({ loading: false });
    }
    this.setState({ user });

    this.updateHeader();
  }

  refreshOrg = async () => {
    const orgRes = await getOrg();
    if (orgRes && orgRes.status === 200) this.setState({ org: orgRes.data });
  };

  reloadApp = (bool) => {
    window.location.reload(bool);
  };

  handleAcceptCookies = () => {
    acceptCookies();
    this.setState({ cookiesAccepted: true });
  };

  handleDeclineCookies = () => {
    this.setState({ cookiesDeclined: true });
  };

  handleRejectCookies = () => {
    revokeCookieAcceptance(false);
    this.setState({ ready: true, upToDate: true, cookiesAccepted: false });
  };

  closePopup = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  logPageView = () => {
    // log pageview to google analytics (except for superadminview)
    if (!window.location.pathname.includes("superadminview")) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  };

  updateHeader = (toValue) => {
    let pageHeader = toValue;
    const path = window.location.pathname;
    if (!toValue) {
      this.logPageView();
      pageHeader = allowables.pageHeaderConversion(path);

      document.title = `Ultimate Scoreboard${
        pageHeader ? ` | ${pageHeader}` : ""
      }`;
    }

    this.setState({
      pageHeader,
      stickyTopNavBar: path === "/",
    });
  };

  switchAccountInUse = async (account, navPath) => {
    await cancelMatchInProgress();
    this.setState({ loading: true, progress: [1] });
    const response = await switchAccount(account, {
      callback: (p) => this.setState({ progress: p }),
      bar: 0,
    });
    if (response.status === 200) {
      localStorage.clear();
      // restart user at profile page unless specified, remaining on same page causes too many issues
      return (window.location = navPath || "/profile");
    } else {
      toast.error(response.data);
    }
    this.setState({ loading: false });
  };

  render() {
    const {
      user,
      org,
      userAccounts,
      pageHeader,
      cookiePromptActive,
      cookiesAccepted,
      cookiesDeclined,
      stickyTopNavBar,
      // mobileAppPromptOpen,
      loading,
      progress,
      contactUsOpen,
      allowStickyItems,
    } = this.state;

    /* 
      this is a workaround to fix issues between React and Google Translate which cause errors
      Solution found here: https://github.com/facebook/react/issues/11538
      Information on this issue: https://martijnhols.nl/gists/everything-about-google-translate-crashing-react
    */
    if (typeof Node === "function" && Node.prototype) {
      const originalRemoveChild = Node.prototype.removeChild;
      Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
          if (console) {
            console.error(
              "Cannot remove a child from a different parent",
              child,
              this
            );
          }
          return child;
        }
        return originalRemoveChild.apply(this, arguments);
      };

      const originalInsertBefore = Node.prototype.insertBefore;
      Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
          if (console) {
            console.error(
              "Cannot insert before a reference node from a different parent",
              referenceNode,
              this
            );
          }
          return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
      };
    }

    return (
      <ErrorBoundary user={user}>
        <DevAlert />
        <HeaderContext.Provider
          value={{
            pageHeader,
            updateHeader: this.updateHeader,
            refreshOrg: this.refreshOrg,
            loading,
            setLoading: (value) => this.setState({ loading: value }),
            progress,
            setProgress: (value) => this.setState({ progress: value }),
            toggleContactForm: () =>
              this.setState({ contactUsOpen: !contactUsOpen }),
            userAccounts,
            switchAccountInUse: this.switchAccountInUse,
            allowStickyItems,
            setAllowStickyItems: (bool) => {
              this.setState({ allowStickyItems: bool });
            },
          }}
        >
          <ToastContainer limit={3} stacked position="bottom-center" />
          <CookieAcceptancePrompt
            cookiePromptActive={cookiePromptActive}
            cookiesAccepted={cookiesAccepted}
            cookiesDeclined={cookiesDeclined}
            onAcceptCookies={this.handleAcceptCookies}
            onDeclineCookies={this.handleDeclineCookies}
          />
          <HttpsRedirect>
            <Elements stripe={stripePromise}>
              <OrgContext.Provider value={org}>
                <BrowserRouter>
                  {/* {user && !user.role?.includes("admin") && (
                      <MobileAppDownloadPrompt
                        isOpen={mobileAppPromptOpen}
                        id="mobileAppPromptOpen"
                        onClose={this.closePopup}
                      />
                    )} */}
                  <NavBar user={user} org={org} sticky={stickyTopNavBar} />
                  <main
                    className="container-fluid"
                    style={{
                      paddingLeft: 25,
                      paddingRight: 25,
                    }}
                  >
                    <MainSwitchRouter />
                  </main>
                  <CustomAdUnit org={org} user={user} page={pageHeader} />
                  <ContactUsModal
                    isOpen={contactUsOpen}
                    setOpen={() => this.closePopup("contactUsOpen")}
                    org={org}
                  />
                </BrowserRouter>
                <LoadingModal loading={loading} progress={progress} />
              </OrgContext.Provider>
            </Elements>
          </HttpsRedirect>
        </HeaderContext.Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
