import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const MultiImageSlider = ({
  images,
  imageDisplayTime = 5000,
  altText,
  ratio = 2 / 3,
  imageBucket = "",
  imageProperty,
  onClickCallback,
  imageTransitionTime = 2,
  maxWidth = 1000,
  sliderContainerStyle = {},
}) => {
  const { width } = useWindowDimensions();
  const [curImageIndex, setCurImageIndex] = useState(0);

  useEffect(() => {
    setCurImageIndex(Math.floor(Math.random() * images?.length || 0));
    const int = setInterval(() => {
      setCurImageIndex((cur) => {
        return (cur + 1) % images.length;
      });
    }, imageDisplayTime);

    return () => {
      clearInterval(int);
    };
  }, [images, imageDisplayTime]);

  const widthPctmargin = width > 1000 ? 0.95 : 0.8;

  return (
    <div
      className="image-slider"
      style={{
        width: width * widthPctmargin,
        height: width * widthPctmargin * ratio,
        maxWidth,
        maxHeight: maxWidth * 0.8 * ratio,
        ...sliderContainerStyle,
      }}
    >
      {images.map((img, idx) => (
        <div
          className={onClickCallback ? "clickable" : ""}
          onClick={() =>
            onClickCallback ? onClickCallback(curImageIndex) : {}
          }
          key={idx}
          data-testid="image-slider"
        >
          <img
            src={imageBucket + (imageProperty ? img[imageProperty] : img)}
            alt={`${altText}-${idx}`}
            className={
              "slider-image" + (idx === curImageIndex ? " active" : "")
            }
            style={{
              transition: `opacity ${imageTransitionTime}s ease-in-out`,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default MultiImageSlider;
