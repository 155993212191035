import React, { Component } from "react";
import Home from "./home";
import LeagueHome from "./leagueHome";
import HeaderContext from "../../context/headerContext";

import { getOrgByHomepage } from "./../../services/spectateService";

class HomeRedirect extends Component {
  static contextType = HeaderContext;
  state = { component: Home, org: null };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const homepage = this.props.location.pathname.slice(1);
    if (homepage && homepage !== "blocked") {
      this.loadData(homepage);
    }
    this.context.setLoading(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.componentDidMount();
    }
  }

  loadData = async (homepage) => {
    const res = await getOrgByHomepage(homepage);
    if (res.status === 200) {
      this.context.updateHeader();
      this.setState({ component: LeagueHome, org: res.data });
      return res.data;
    }
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  render() {
    const { org } = this.state;
    return (
      <this.state.component
        {...this.props}
        pageOrg={org}
        onRefresh={this.loadData}
      />
    );
  }
}

export default HomeRedirect;
