import React, { Component } from "react";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import LoginForm from "../loginLogoutRegister/loginForm";
import RegisterForm from "../loginLogoutRegister/registerForm";
import { getCurrentUser } from "../../services/userService";
import SectionalFeatures from "./sectionalFeatures";
import HomepageTextSection from "./homepageTextSection";
import HeaderContext from "../../context/headerContext";
import MultiImageSlider from "../common/pageComponents/multiImageSlider";
import { CLink } from "../common/customs/customLinks";

// import all images
let balls, fields, players, training, wallpapers, data;
balls = fields = players = training = wallpapers = data = { keys: () => [] };
try {
  balls = require.context("../../assets/balls", true);
  fields = require.context("../../assets/fields", true);
  players = require.context("../../assets/players", true);
  training = require.context("../../assets/training", true);
  wallpapers = require.context("../../assets/wallpapers", true);
  data = require.context("../../assets/data", true);
} catch (error) {
  // caught for testing purposed
}

const images = {
  balls: balls.keys().map((image) => balls(image)),
  players: players.keys().map((image) => players(image)),
  training: training.keys().map((image) => training(image)),
  fields: fields.keys().map((image) => fields(image)),
  wallpapers: wallpapers.keys().map((image) => wallpapers(image)),
  data: data.keys().map((image) => data(image)),
};

class Home extends Component {
  static contextType = HeaderContext;
  state = {
    openForm: "",
    user: null,
  };

  componentDidMount() {
    const user = getCurrentUser();
    this.setState({ user });
  }

  handleOpenForm = (type) => {
    this.context.setAllowStickyItems(!type);
    this.setState({ openForm: type });
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
          margin: -10,
        }}
      >
        <div
          className="bg-light round-corners"
          style={{ padding: 10, marginRight: 20, float: "left" }}
        >
          <CLink
            path="/referees?page=scorecards"
            button={true}
            buttonStyle="btn btn-sm btn-secondary"
          >
            Refs/Umps
          </CLink>
        </div>
        {!this.state.user ? (
          <div
            className={
              "row" + (this.context.allowStickyItems ? " sticky-top" : "")
            }
            style={{ backgroundColor: "transparent" }}
          >
            {/* <div className="col"></div> */}
            {["Login", "Register"].map((type) => (
              <div
                key={type}
                className="col bg-light round-corners"
                style={{ padding: 10, marginRight: 20 }}
              >
                <button
                  className="btn btn-block btn-sm btn-info"
                  onClick={() => this.handleOpenForm(type)}
                >
                  {type === "Register" ? "Signup" : type}
                </button>
              </div>
            ))}
          </div>
        ) : null}

        <div style={{ margin: "auto" }}>
          <HomepageTextSection
            header="Transform Your League Experience"
            text={
              <div>
                Experience a revolution in league management with the
                comprehensive platform from Ultimate Scoreboard. Streamline
                administrative tasks from scheduling to team management, and
                create a seamless experience for players, referees, and
                administrators alike. Elevate your league to new heights with
                our advanced tools, empowering you to focus on what truly
                matters: the game itself.
              </div>
            }
          />
          <MultiImageSlider
            images={images.wallpapers}
            imageDisplayTime={6000}
            ratio={1 / 6}
            altText={"divider1"}
          />
        </div>
        <SectionalFeatures images={images} />
        <HomepageTextSection
          header="Sign Up Now!"
          text={
            <div>
              <p>
                Ready to revolutionize your league management? Register now to
                streamline operations, empower your league, and unlock a new era
                of efficiency and engagement. You can get started for free.
              </p>
              <button
                className="btn btn-block btn-info btn-lg"
                onClick={() => this.handleOpenForm("Register")}
              >
                Get Started
              </button>
              <div className="text-center m-2">
                <CLink path="/pricing">View Pricing</CLink>
              </div>
            </div>
          }
        />
        <p className="text-center">
          Have a question?{" "}
          <span className="clink" onClick={this.context.toggleContactForm}>
            Contact Us
          </span>
        </p>
        <div style={{ height: 50 }} />
        <BasicModal
          isOpen={!!this.state.openForm}
          onClose={() => {
            this.handleOpenForm("");
          }}
          style={{
            content: {
              ...popupStyle.content,
              maxWidth: "90%",
              maxHeight: "90%",
            },
          }}
        >
          {this.state.openForm === "Register" ? (
            <RegisterForm />
          ) : (
            <LoginForm />
          )}
        </BasicModal>
      </div>
    );
  }
}

export default Home;
