import React, { Component } from "react";
import orderBy from "lodash/orderBy";
import toast from "../../utils/toast";

import { paginate } from "../../utils/paginate";
import Pagination from "../common/dataSorting/pagination";
import SeasonsTable from "./seasonsTable";
import SearchBox from "../common/dataSorting/searchBox";
import ExcelDownload from "../common/pageComponents/excelDownload";
import QuickLinks from "../common/pageComponents/quickLinks";
import { getSeasons, deleteSeason } from "../../services/seasonsService";
import HeaderContext from "../../context/headerContext";
import CustomConfirm from "../common/customs/customConfirm";
import SeasonsButtonLineup from "./seasonsButtonLineup";
import { CLink } from "../common/customs/customLinks";
import allowables from "../../utils/allowables";
import { getCurrentUser } from "../../services/userService";

class Seasons extends Component {
  static contextType = HeaderContext;
  state = {
    seasons: [],
    sortColumn: { path: "active", order: "desc" },
    searchQuery: "",
    pageSize: allowables.pageSize,
    currentPage: 1,
    selectedSeason: {},
    deleteOpen: false,
  };

  loadData = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await getSeasons(
      { callback: this.indicateProgress, bar: 0 },
      true
    );
    if (res.status === 200) {
      this.setState({ seasons: res.data });
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  componentDidMount() {
    this.loadData();
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path) {
      this.setState({ sortColumn });
    }
  };

  downloadColumns = [
    "name",
    "registrationEndDate",
    "startDate",
    "endDate",
    "location",
    "active",
  ];

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePreviousPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  handleNextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
  };

  handleDelete = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    this.setState({ deleteOpen: false });
    const res = await deleteSeason(this.state.selectedSeason._id);
    if (res.status === 200) return this.loadData();
    toast.error(res.data);
    this.context.setLoading(false);
  };

  getPageData = () => {
    const { seasons, sortColumn, searchQuery } = this.state;
    let filteredSeasons = seasons;

    if (searchQuery)
      filteredSeasons = seasons.filter((s) =>
        s.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sortedSeasons = orderBy(
      filteredSeasons,
      [sortColumn.path],
      [sortColumn.order]
    );

    const seasonsByPage = paginate(
      sortedSeasons,
      this.state.currentPage,
      this.state.pageSize
    );

    return { seasonsByPage, filteredSeasons, sortedSeasons };
  };

  render() {
    const {
      seasons,
      sortColumn,
      searchQuery,
      pageSize,
      currentPage,
      selectedSeason,
      deleteOpen,
    } = this.state;
    const { seasonsByPage, filteredSeasons, sortedSeasons } =
      this.getPageData();
    const user = getCurrentUser();

    return seasons.length === 0 ? (
      <CLink
        path={"/seasons?q=new"}
        button={true}
        buttonStyle="btn-lg btn-info btn-block"
      >
        Add New Season
      </CLink>
    ) : (
      <div>
        <div className="row">
          <div className="col text-center">
            <p className="header-p">
              Showing {seasonsByPage.length} of {sortedSeasons.length} seasons.
              There are {seasons.length} total seasons.
            </p>
          </div>
          {user.role.includes("admin") ? (
            <div className="col text-center">
              <SeasonsButtonLineup />
            </div>
          ) : null}
        </div>
        {seasons.length > 0 && (
          <div>
            <div className="row">
              <div className="col">
                <SearchBox
                  value={searchQuery}
                  onChange={(q) => this.setState({ searchQuery: q })}
                  placeholder="Search by season name..."
                />
              </div>
              <div className="col-2">
                <ExcelDownload
                  data={seasons}
                  columns={this.downloadColumns}
                  dataName="seasons"
                  tooltipDirection="top-left"
                />
              </div>
            </div>
            <SeasonsTable
              seasonsByPage={seasonsByPage}
              onSort={this.handleSort}
              sortColumn={sortColumn}
              onDelete={(season) => {
                this.setState({ deleteOpen: true, selectedSeason: season });
              }}
            />
            <Pagination
              itemsCount={filteredSeasons.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              onPreviousPage={this.handlePreviousPage}
              onNextPage={this.handleNextPage}
            />
          </div>
        )}
        <hr />
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
        <CustomConfirm
          dialog={`Are you sure you want to delete ${selectedSeason.name}?`}
          isOpen={deleteOpen}
          callback={this.handleDelete}
          close={() => this.setState({ deleteOpen: false })}
          focused={true}
        />
      </div>
    );
  }
}

export default Seasons;
