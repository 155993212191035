import React, { useEffect, useContext, useState } from "react";

import useSearchParams from "../../hooks/useSearchParams";
import HeaderContext from "../../context/headerContext";
import { getLeagues, getUmbrella } from "../../services/umbrellaService";
import toast from "../../utils/toast";
import NotFound from "../notFound";
import ComponentLoading from "../common/loading/componentLoading";
import MiniHeader from "../common/pageComponents/miniHeader";
import UmbrellaInfo from "./umbrellaInfo";
import allowables from "../../utils/allowables";
import IconRender from "../common/icons/iconRender";
import SearchBox from "../common/dataSorting/searchBox";
import OrgLogo from "../common/pageComponents/orgLogo";
import QRCodeRender from "../common/pageComponents/qrCode";
import LeagueWebpage from "../leagueWebpage/leagueWebpage";

const UmbrellaHomepage = ({ location }) => {
  const { setLoading } = useContext(HeaderContext);
  const [localLoading, setLocalLoading] = useState(true);
  const params = useSearchParams(location);
  const [umbrella, setUmbrella] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [search, setSearch] = useState("");
  const [openLeagues, setOpenLeagues] = useState([]);

  const getData = async () => {
    setLocalLoading(true);
    const umbrellaID = params.q;
    if (umbrellaID) {
      setLoading(true);
      const umbrellaRes = await getUmbrella(umbrellaID);
      if (umbrellaRes.status === 200) {
        const leaguesRes = await getLeagues(umbrellaID);
        setUmbrella(umbrellaRes.data);
        if (leaguesRes.status === 200) {
          setLeagues(leaguesRes.data);
        } else {
          toast.error(leaguesRes.data);
        }
      }
      setLoading(false);
    }
    setLocalLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const regLinks = (league) => {
    return league?.misc?.activeRegistrationLinks || {};
  };

  if (localLoading) return <ComponentLoading />;
  if (!umbrella) return <NotFound />;

  const { backgroundColor, color } = umbrella?.webpage || {};

  const sortAndFilterLeagues = () => {
    let filteredLeagues = [...leagues].filter(
      (l) =>
        l.name.toLowerCase().includes(search.toLowerCase()) ||
        allowables
          .translateSportName(l)
          .toLowerCase()
          .includes(search.toLowerCase())
    );

    return filteredLeagues;
  };

  const handleToggleLeagueOpen = (leagueIndex) => {
    const currentOpen = [...openLeagues];
    const index = currentOpen.findIndex((idx) => idx === leagueIndex);
    if (index >= 0) currentOpen.splice(index, 1);
    else currentOpen.push(leagueIndex);
    setOpenLeagues(currentOpen);
  };

  return (
    <div className="centered-small-input-area">
      <MiniHeader
        overrideStyle={{
          backgroundColor: color,
          color: backgroundColor,
        }}
        onClick={() => window.open(umbrella.webAddress, "_blank")}
      >
        {umbrella.name}
      </MiniHeader>
      <div
        className="form-divided-section"
        style={{
          backgroundColor,
          color,
          borderColor: color,
        }}
      >
        <UmbrellaInfo info={umbrella.webpage} />
        <QRCodeRender
          value={allowables.url + `/organization?q=${umbrella._id}`}
          margin={10}
          size={75}
        />

        <div
          style={{
            backgroundColor: color,
            margin: 10,
            height: 2,
          }}
        />
        <h5 className="text-center">
          <b>Find a League</b>
        </h5>
        <SearchBox
          value={search}
          onChange={(q) => setSearch(q)}
          placeholder="Search by sport or league name..."
        />
        {sortAndFilterLeagues().map((league, idx) => (
          <div
            key={league._id}
            className="pop-box text-center"
            style={{
              borderWidth: 2,
              backgroundColor,
              color,
              borderColor: color,
              boxShadow: `4px 4px 4px ${color}`,
              marginBottom: 15,
            }}
          >
            {league.logo ? (
              <OrgLogo _id={league?.logo} name={league?.name} />
            ) : null}
            <h6>
              <b>{league.name}</b>
            </h6>
            <p>
              {allowables.ballIcon(allowables.translateSportName(league))}
              &nbsp;&nbsp;
              {allowables.translateSportName(league)}&nbsp;&nbsp;
              {allowables.ballIcon(allowables.translateSportName(league))}
            </p>
            {regLinks(league).freeAgent || regLinks(league).teamCaptain ? (
              <div className="pop-box">
                <a
                  href={`/freeagent?id=${league._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="clink main-theme"
                >
                  Register{" "}
                  {regLinks(league).teamCaptain
                    ? " your Team" + (regLinks(league).freeAgent ? " or" : "")
                    : ""}{" "}
                  {regLinks(league).freeAgent ? " as a Free Agent" : ""}
                </a>
              </div>
            ) : null}
            {league.spectatingAllowed ? (
              <div>
                <a
                  href={`/${league.spectatingAllowed}?division`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="clink main-theme"
                  style={{
                    color,
                  }}
                >
                  <IconRender name="table" />
                  &nbsp;&nbsp; Live League Stats&nbsp;&nbsp;
                  <IconRender name="externallink" />
                </a>
              </div>
            ) : null}

            {league.webpage ? (
              <MiniHeader
                overrideStyle={{
                  backgroundColor: league.webpage.logoBgColor || color,
                  color: league.webpage.themeTextColor || backgroundColor,
                }}
                onClick={() => handleToggleLeagueOpen(idx)}
              >
                <IconRender
                  name={openLeagues.includes(idx) ? "chevronup" : "chevrondown"}
                />
                &nbsp;&nbsp;More Info&nbsp;&nbsp;
                <IconRender
                  name={openLeagues.includes(idx) ? "chevronup" : "chevrondown"}
                />
              </MiniHeader>
            ) : null}
            {openLeagues.includes(idx) ? (
              <LeagueWebpage pageOrg={league} />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UmbrellaHomepage;
