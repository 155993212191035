import React from "react";

import TeamLogo from "../common/pageComponents/teamLogo";
import allowables from "../../utils/allowables";
import { renderFlexSection } from "../../utils/textUtils";

const StandingsCardView = ({ standings, sport }) => {
  if (!standings || (!standings.standings.length && !standings.groups.length))
    return (
      <p className="text-center cardview-card">
        There are no standings available for this division
      </p>
    );

  const renderStats = (pos, points, record, scored, conceded, diff) => {
    return renderFlexSection(
      [10, 20, 20, 10, 10, 10],
      [pos, points, record, scored, conceded, diff],
      [{}, { fontWeight: "bold" }],
      { paddingLeft: 15 }
    );
  };

  const renderTable = (table) => {
    return (
      <div
        className="form-divided-section"
        style={{ marginLeft: 5, marginRight: 5, padding: 0 }}
      >
        {renderStats("Pos", "Pts", "W/L", ...statNames[sport])}
        {table.map((row, idx) => {
          const backgroundColor = row.teamColor1;
          const color = allowables.getTextColor(backgroundColor);
          return (
            <div
              key={idx}
              className="cardview-card"
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <div
                style={{ display: "flex", backgroundColor, color }}
                className="cardview-text cardview-pop"
              >
                <div style={{ flex: "5%", padding: 2 }}>
                  <b>{row.position}</b>
                </div>
                <div
                  style={{
                    flex: "15%",
                    alignSelf: "center",
                    marginLeft: 5,
                    marginRight: 5,
                    padding: 2,
                  }}
                >
                  <TeamLogo team={row} name={row.name} size={35} />
                </div>
                <div style={{ flex: "75%" }}>
                  <div className="cardview-text">
                    <b>{row.name}</b>
                  </div>
                </div>
              </div>
              {renderStats(
                "",
                row.points,
                `${row.wins}-${row.losses}-${row.ties}`,
                row.goalsScored,
                row.goalsConceded,
                row.goalDifference
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderGroupHeader = (text) => {
    return <div className="cardview-card cardview-header">{text}</div>;
  };

  const statNames = {
    soccer: ["GS", "GA", "GD"],
    football: ["PS", "PA", "PD"],
    "diamond sports": ["RS", "RA", "RD"],
    "simple scoring": ["PS", "PA", "PD"],
  };

  return (
    <div className="cardview">
      {standings.standings.length > 0 && standings.groups.length > 0
        ? renderGroupHeader("Main")
        : null}
      {renderTable(standings.standings)}

      <div className="card-padding" />
      {standings.groups.map((group) => {
        return (
          <div key={group}>
            {renderGroupHeader(`Group: ${group}`)}
            {renderTable(standings.groupStandings[group])}
            <div className="card-padding" />
          </div>
        );
      })}
    </div>
  );
};

export default StandingsCardView;
