import React, { Component } from "react";
import Table from "../common/table/table";
import { getCurrentUser } from "../../services/userService";
import { makeTableButton, combineButtons } from "./../../utils/buttons";
import { CLink } from "../common/customs/customLinks";
import EventImage from "../assignedMatch/eventImage";
import TeamLogo from "../common/pageComponents/teamLogo";
import VaccineStatus from "./../vaccine/vaccineStatus";
import CodeOfConductStatus from "../codeOfConduct/codeOfConductStatus";
import allowables from "../../utils/allowables";
import Tooltip from "./../common/tooltip/tooltip";
import { isPaid } from "../../utils/paymentsUtil";

class PlayersTable extends Component {
  columns = [
    {
      key: "image",
      content: (p) => <EventImage player={p} />,
    },
    {
      path: "name",
      label: "Player Name",
      content: (p) => (
        <CLink path={`/players?info&q=${p._id}`}>
          {allowables.splitName(p.name)}
          {p._id === this.props.thisID ? <b> - (me)</b> : ""}
        </CLink>
      ),
    },
    {
      path: "teamLogo",
      label: "",
      content: (p) =>
        p.teamLogo ? (
          <TeamLogo team={p.teamLogo} name={p.teamName} size={40} />
        ) : null,
    },
    {
      path: "teamName",
      label: "Team Name",
      content: (p) =>
        p.teamName === "Free Agents" ? (
          p.teamName
        ) : (
          <CLink path={`/teams?info&q=${p.teamID}`}>{p.teamName}</CLink>
        ),
    },
    {
      path: "shirtNumber",
      label:
        this.props.playersByPage.length > 0 &&
        this.props.playersByPage[0].sport.toLowerCase().includes("diamond")
          ? "Roster Position"
          : "Shirt Number",
    },
    { path: "suspendedFlag", label: "Unserved Suspension" },
  ];

  link = {
    key: "link",
    content: (player) => (
      <button
        className="btn-href-link"
        onClick={() =>
          this.props.onClickLink(
            "/signupteam?id=" +
              this.props.team._id +
              "&name=" +
              this.props.team.name +
              "&orgID=" +
              this.props.team.orgID +
              "&orgName=" +
              this.props.team.orgName +
              "&role=player&sport=" +
              this.props.team.sport +
              "&playerID=" +
              player._id +
              "&playerFirstName=" +
              player.name.split("%20%")[0].split(" ").join("--") +
              "&playerLastName=" +
              (player.name.split("%20%")[1]
                ? player.name.split("%20%")[1].split(" ").join("--")
                : ""),
            player
          )
        }
      >
        {!player.userID && !player.email
          ? "Click to send an email for user registration"
          : player.email
          ? `${player.email}: pending`
          : ""}
      </button>
    ),
  };

  constructor(props) {
    super(props);
    const user = this.props.getCurrentUser
      ? this.props.getCurrentUser()
      : getCurrentUser();
    let buttons = [];
    if (user && user.role?.includes("admin")) {
      buttons.push(makeTableButton("delete", props.onDelete));
    }
    if (user?.sport?.toLowerCase().includes("soccer"))
      this.columns.push({
        path: "runningYellows",
        label: "Acc. Yellows",
      });
    if ((user && user.role?.includes("admin")) || props.captain) {
      if (props.org) {
        if (props.org.requireVaccine === "Yes")
          this.columns.push({
            path: "vaccine",
            label: "Vaccine",
            content: (p) => <VaccineStatus user={p.userID} table />,
          });
        if (props.org.codeOfConduct && props.org.codeOfConduct.length > 0)
          this.columns.push({
            path: "codeOfConduct",
            label: "Code of Conduct",
            content: (p) => <CodeOfConductStatus user={p.userID} table />,
          });
        if (props.org.stripe.whoPays === "Player") {
          this.columns.push({
            path: "payment",
            label: "Payment Date",
            content: (p) =>
              isPaid(p.payment)
                ? allowables.shortDate(p.payment.datePaid, true)
                : "",
          });
        }
      }
      if (props.link) {
        this.columns.push(this.link);
      }
      if (props.captain && props.onMoveToFreeAgents)
        buttons.push(makeTableButton("remove", props.onMoveToFreeAgents));
      buttons.push(makeTableButton("edit", "players"));
      this.columns.push(combineButtons(buttons));
    }
  }

  render() {
    const { playersByPage, onSort, sortColumn, thisID, freeAgents } =
      this.props;
    const user = getCurrentUser();

    let newColumns = [...this.columns];
    if (freeAgents) {
      newColumns = [
        ...this.columns,
        {
          path: "waiverWire",
          label: "Waiver Wire Expires",
          content: (p) =>
            p.waiverWire && p.waiverWire.expiration ? (
              <Tooltip
                overrideStyle={{ width: 250 }}
                content={
                  <span>
                    <h6>
                      <b>Waiver Wire Information</b>
                    </h6>
                    <ul>
                      <li>
                        To recruit a player on the waiver wire you must place a
                        bid. You can only have one bid active at any time.
                      </li>
                      <li>
                        Once the waiver wire has expired the team with the
                        highest waiver priority who placed a bid will recruit
                        that player. If there are no bids then any team captain
                        may recruit that player going forward.
                      </li>
                      <li>
                        Waiver priority is first assigned when you place your
                        bid for a player or when the first waiver for the league
                        expires. The team that wins the waiver is placed at the
                        bottom of the priority list.
                      </li>
                    </ul>
                  </span>
                }
                direction="left"
              >
                <p>{allowables.shortDate(p.waiverWire.expiration, true)}</p>
              </Tooltip>
            ) : (
              "Open"
            ),
        },
      ];
      if (user.role.includes("captain")) {
        newColumns = [
          ...newColumns,
          makeTableButton("recruit", this.props.onRecruit),
        ];
      }
    }

    return (
      <Table
        columns={newColumns}
        data={playersByPage}
        sortColumn={sortColumn}
        onSort={onSort}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
        backgroundKey={"suspendedFlag"}
        backgroundStyle={"soccer-red-card text-white"}
        thisID={thisID}
      />
    );
  }
}

export default PlayersTable;
