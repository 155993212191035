import React, { useState } from "react";
import { FaFootballBall, FaFlag } from "react-icons/fa";
import { GiAmericanFootballHelmet } from "react-icons/gi";

import MatchPopupFootball from "./matchPopupFootball";
import CustomConfirm from "./../../common/customs/customConfirm";
import HorizontalCounter from "../../common/pageComponents/horizontalCounter";
import Checkbox from "../../common/form/checkBox";
import { createFootballEventText } from "../../../utils/footballFunctions";

const FootballTeamSegment = ({ match, players, teamOrder, onEvent }) => {
  const [openModal, setOpenModal] = useState(false);
  const [dialog, setDialog] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [event, setEvent] = useState(null);
  const [points, setPoints] = useState(0);
  const [player, setPlayer] = useState(null);
  const [player2, setPlayer2] = useState(null);
  const [type, setType] = useState(null);
  const [team, setTeam] = useState("home");
  const [playersByTeam, setPlayersByTeam] = useState([]);
  const [eventHeader, setEventHeader] = useState("");
  const [color, setColor] = useState("");
  const reset = () => {
    setPlayer(null);
    setPlayer2(null);
    setType(null);
    setPoints(0);
  };

  const buttons = [
    {
      name: "Score",
      id: "score",
      Icon: FaFootballBall,
      buttonColor: "btn-info btn-lg",
      color: "bg-info text-white",
    },
    {
      name: "Defense and Special Teams",
      id: "defense",
      Icon: GiAmericanFootballHelmet,
      buttonColor: "btn-light",
      color: "bg-light text-dark",
    },
    {
      name: "Penalty",
      id: "penalty",
      Icon: FaFlag,
      buttonColor: "soccer-yellow-card",
      color: "soccer-yellow-card-bg",
    },
  ];

  const openConfirm = (team, type, player, player2) => {
    setDialog(
      createFootballEventText(
        {
          name: player.name,
          teamName: player.teamName,
          player2ID: player2?._id,
          player2Name: player2?.name,
          ...type,
        },
        false
      )
    );
    setEvent({ team, type, player, player2 });
    setConfirmOpen(true);
  };

  const handleEvent = () => {
    onEvent(event.player, { ...event.type, points }, event.player2, event.team);
    setOpenModal(false);
    reset();
  };

  return (
    <div>
      <div className="row">
        {teamOrder.map((team) => {
          return (
            <div className="col" key={team}>
              {buttons.map((b, i) => {
                return (
                  <React.Fragment key={b.id}>
                    <button
                      className={"btn btn-block " + b.buttonColor}
                      onClick={() => {
                        setTeam(team);
                        setPlayersByTeam(players[team]);
                        setEventHeader(b.name);
                        setColor(b.color);
                        setOpenModal(true);
                      }}
                    >
                      <b.Icon /> {b.name}
                    </button>
                    <br />
                    {i === 0 && <br />}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
      <MatchPopupFootball
        team={team}
        players={playersByTeam}
        onClick={openConfirm}
        popupOpen={openModal}
        closeModal={() => setOpenModal(false)}
        teamHeader={match[team + "TeamName"]}
        eventHeader={eventHeader}
        color={color}
        match={match}
        setPoints={setPoints}
        type={type}
        setType={setType}
        player={player}
        setPlayer={setPlayer}
        player2={player2}
        setPlayer2={setPlayer2}
        reset={reset}
      />
      <CustomConfirm
        dialog={dialog}
        isOpen={confirmOpen}
        callback={handleEvent}
        close={() => setConfirmOpen(false)}
        split="%s%"
        centerAllText
      >
        {event &&
          event.type &&
          event.type.eventType &&
          (event.type.eventType.includes("Extra Point") ||
          event.type.eventType.includes("Penalty") ? (
            <HorizontalCounter
              header={
                event?.type?.eventType.includes("Penalty") ? "Yards" : "Points"
              }
              maxValue={event?.type?.eventType.includes("Penalty") ? 120 : 14}
              minValue={event?.type?.eventType.includes("Penalty") ? 0 : 1}
              value={points}
              setValue={setPoints}
              className="icon-info icon-lg"
              label="points"
            />
          ) : ["interception", "fumble"].includes(event.type.eventStyle) ? (
            <Checkbox
              id="td"
              onChange={() => setPoints(points === 6 ? 0 : 6)}
              label={"Returned for a Touchdown"}
              value={points === 6}
            />
          ) : null)}
      </CustomConfirm>
    </div>
  );
};

export default FootballTeamSegment;
