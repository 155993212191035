import http from "./httpService";

export async function getUmbrella(id) {
  try {
    return await http.get(http.umbrellaEndpoint + (id ? "/" + id : ""));
  } catch (ex) {
    return ex.response;
  }
}

export async function addLeagueToUmbrella(newUmbrella) {
  try {
    return await http.post(http.umbrellaEndpoint, newUmbrella);
  } catch (ex) {
    return ex.response;
  }
}

export async function removeLeagueFromUmbrella() {
  try {
    return await http.put(http.umbrellaEndpoint + "/remove");
  } catch (ex) {
    return ex.response;
  }
}

export async function editUmbrella(umbrella) {
  try {
    return await http.put(http.umbrellaEndpoint, umbrella);
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteUmbrella() {
  try {
    return await http.delete(http.umbrellaEndpoint);
  } catch (ex) {
    return ex.response;
  }
}

export async function getLeagues(umbrellaID) {
  try {
    return await http.get(http.umbrellaEndpoint + "/leagues/" + umbrellaID);
  } catch (ex) {
    return ex.response;
  }
}
