import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import toast from "../../utils/toast";

import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import allowables from "../../utils/allowables";
import { getCurrentUser } from "../../services/userService";
import { updateRefereeCode } from "../../services/orgService";
import HeaderContext from "../../context/headerContext";

class RefereeCodeForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      username: "",
      passcode: "",
    },
    errors: {},
    isOpen: false,
    refOrUmp: "Referee",
  };

  emptyCode = {
    username: "",
    passcode: "",
  };

  componentDidMount() {
    this.setState({ refOrUmp: allowables.refOrUmp(getCurrentUser().sport) });
  }

  schema = {
    username: Joi.string().required().max(50).label("Username"),
    passcode: Joi.string().required().max(50).label("Passcode"),
  };

  handleCancel = async (e) => {
    e.preventDefault();
    this.context.setLoading(true);
    const res = await updateRefereeCode(this.emptyCode);
    if (res.status === 200) {
      toast.success(
        `${this.state.refOrUmp} passcode removed. Set a new one to allow your ${this.state.refOrUmp}s to access matches on the mobile app.`
      );
      this.setState({ data: this.emptyCode, isOpen: false });
    } else {
      toast.error(res.data);
    }
    this.context.setLoading(false);
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    const res = await updateRefereeCode(this.state.data);
    if (res.status === 200) {
      toast.success(`${this.state.refOrUmp} passcode updated`);
      this.setState({ isOpen: false });
    } else {
      toast.error(res.data);
    }
    this.context.setLoading(false);
  };

  render() {
    return (
      <div>
        <button
          className="btn btn-sm btn-secondary btn-block"
          onClick={() => this.setState({ isOpen: true })}
        >
          Change {this.state.refOrUmp} Passcode
        </button>
        <BasicModal
          isOpen={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          style={{
            content: {
              ...popupStyle.content,
            },
          }}
        >
          <p className="text-center" style={{ maxWidth: 500 }}>
            Set your username and passcode combo to distribute to your{" "}
            {this.state.refOrUmp}s and they will be able to take charge of
            scorecards on the mobile app without the need to create an account
            or have a match assigned to them.
            <br />
            The username and passcode are never sent out so they will not be
            visible here, but once they are set they remain the same until
            updated here.
            <br />
            In the case of the passcode getting into the wrong hands removing
            the passcode will immediately lock out submission of scorecards from
            non authenticated users.
          </p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput(
              "username",
              `${this.state.refOrUmp} Username`,
              "",
              "string",
              "",
              "",
              "",
              {
                header: "Referee/Umpire Username",
                text: `If you have ${this.state.refOrUmp.toLowerCase()}s who will not be registering for your league
                        they can still take charge of matches on the mobile app with this username and passcode combo.
                        The username you choose must be unique within Ultimate Scoreboard.
                        In order to never expose these two fields the Username and Passcode must be reset each time you make changes to your settings.`,
                direction: "right",
                className: "icon-mobile",
              },
              "",
              true
            )}
            {this.renderInput(
              "passcode",
              `${this.state.refOrUmp} Passcode`,
              "",
              "string",
              "",
              "",
              "",
              {
                header: "Referee/Umpire Username",
                text: `If you have ${this.state.refOrUmp.toLowerCase()}s who will not be registering for your league
                        they can still take charge of matches on the mobile app with this username and passcode combo.
                        The passcode will never be sent to the client but must be given out to ${this.state.refOrUmp.toLowerCase()}s so please do not reuse your own passwords.`,
                direction: "right",
                className: "icon-mobile",
              },
              "",
              true
            )}
            <div className="row">
              <div className="col">
                {this.renderCancelButton("Remove Passcode")}
              </div>
              <div className="col text-right">
                {this.renderValidatedButton("Submit")}
              </div>
            </div>
          </form>
        </BasicModal>
      </div>
    );
  }
}

export default RefereeCodeForm;
