import React, { useState } from "react";
import { IconContext } from "react-icons";

import BasicModal from "./../common/pageComponents/basicModal";
import IconRender from "../common/icons/iconRender";
import TooltipButton from "../common/tooltip/tooltipButton";
import PenaltyVisualization from "./soccer/penaltyVisualization";

const Penalties = ({
  match,
  penaltyVisualization,
  onChangePenalties,
  teamOrder,
  onSubmit,
  header,
}) => {
  const [open, setOpen] = useState(false);

  const doSubmit = () => {
    setOpen(false);
    onSubmit();
  };

  return (
    <React.Fragment>
      <button
        className="btn btn-block btn-sm btn-secondary"
        onClick={() => setOpen(true)}
      >
        Go To Penalties
      </button>
      <BasicModal
        isOpen={open}
        onClose={setOpen}
        header={
          <div className="bg-info">
            <h4 className="text-center">
              <b>Penalties</b>
            </h4>
          </div>
        }
      >
        {header}
        <div className="row">
          {teamOrder.map((team, idx) => {
            return (
              <div className="col text-center" key={team}>
                <br />
                <IconContext.Provider value={{ className: "icon-light" }}>
                  <TooltipButton
                    tooltipText="Scored"
                    tooltipDirection={idx ? "left" : "right"}
                    buttonClass="btn btn-info btn-lg"
                    onClick={() => onChangePenalties(team, true)}
                    testId={team + "-penalty-scored"}
                  >
                    <IconRender name="soccer" />
                  </TooltipButton>
                  <br />
                  <TooltipButton
                    tooltipText="Missed"
                    tooltipDirection={idx ? "left" : "right"}
                    buttonClass="btn btn-dark btn-lg"
                    onClick={() => onChangePenalties(team, false)}
                    testId={team + "-penalty-missed"}
                  >
                    <IconRender name="circle" />
                  </TooltipButton>
                  <br />
                  <TooltipButton
                    tooltipText="Undo Last"
                    tooltipDirection={idx ? "left" : "right"}
                    buttonClass="btn btn-secondary btn-sm"
                    onClick={() => onChangePenalties(team, false, true)}
                    testId={team + "-penalty-undo"}
                    disabled={!penaltyVisualization[team].length}
                  >
                    <IconRender name="undo" />
                  </TooltipButton>
                </IconContext.Provider>
              </div>
            );
          })}
        </div>

        <PenaltyVisualization
          match={match}
          teamOrder={teamOrder}
          visualization={penaltyVisualization}
        />

        <br />
        <p className="text-center">
          If you still need to handle approvals you can close the penalties
          screen, you will not lose your input
        </p>
        <button className="btn btn-block btn-info" onClick={doSubmit}>
          Submit Scorecard
        </button>
      </BasicModal>
    </React.Fragment>
  );
};

export default Penalties;
