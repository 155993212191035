import React, { useContext } from "react";
import MultiImageSlider from "../common/pageComponents/multiImageSlider";
import useWindowDimensions from "../../utils/useWindowDimensions";
import HeaderContext from "../../context/headerContext";

const sections = [
  {
    navHeader: "Sports Coverage",
    imageKey: "balls",
    text: (
      <p>
        We offer coverage for a wide range of sports, ensuring there's something
        for everyone. From traditional favorites like soccer and baseball to
        other fun options like kickball, our platform caters to diverse sporting
        preferences. Each sport comes with tailored features designed to enhance
        the experience. For example, soccer leagues can track yellow and red
        cards, while baseball leagues can delve into detailed player stats like
        batting average and ERA. Don't see your sport listed? No problem! Users
        can request new sports, and our team is continuously expanding our
        offerings to meet demand.
      </p>
    ),
  },
  {
    navHeader: "Team Management",
    imageKey: "training",
    text: (
      <p>
        Team captains are the backbone of any recreational league, handling
        critical tasks like player registration and roster updates. Empower your
        captains with the tools like integrated messaging and tactics to keep
        their players informed and engaged throughout the season. Completely
        hand off player sign-ups and lower your user management burden.
      </p>
    ),
  },
  {
    navHeader: "Scheduling & Brackets",
    imageKey: "players",
    text: (
      <p>
        Take the hassle out of scheduling with our Automatic Match Scheduling
        feature. Simply input field availability and preferred times, and our
        system will generate the entire season schedule. Managing playoffs has
        never been easier. Our platform supports the creation of playoff
        brackets for up to 1024 teams with just a few clicks. Teams are moved to
        the next match based on their result in the previous round. Create the
        schedule and never have to touch it again.
      </p>
    ),
  },
  {
    navHeader: "Performance Insights",
    imageKey: "fields",
    text: (
      <p>
        Keep track of player stats and manage disputes with ease using our
        integrated features. Live Scorecards and Player Images enhance the user
        experience by providing real-time updates and visual player
        identification. Enjoy peace of mind knowing that our platform
        facilitates a peaceful dispute resolution processes, helping maintain a
        fair and enjoyable playing environment for all participants.
      </p>
    ),
  },
];
const finalSection = {
  navHeader: "Financial Hub",
  imageKey: "data",
  text: (
    <p>
      Simplify your financial management with our integrated payments. Easily
      monitor all league-related transactions in one centralized hub. With
      real-time insights into payment statuses and comprehensive transaction
      histories, you can ensure financial transparency and streamline accounting
      processes. Say goodbye to manual record-keeping and hello to efficient
      payment tracking with our platform.
    </p>
  ),
};

const SectionalFeatures = ({ images }) => {
  const { width } = useWindowDimensions();
  const { allowStickyItems } = useContext(HeaderContext);
  return (
    <div>
      <div>
        <nav
          className={"feature-nav" + (allowStickyItems ? " sticky-top" : "")}
        >
          <ul
            className="feature-nav-list"
            style={
              width > 750
                ? { justifyContent: "center" }
                : { paddingLeft: 10, paddingRight: 10 }
            }
          >
            {[...sections, finalSection].map(({ navHeader }, idx) => (
              <li key={idx} className="feature-nav-item">
                <a href={`#${navHeader}`} className="feature-nav-link">
                  {navHeader}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        {sections.map(({ navHeader, text, imageKey }, idx) => (
          <section key={idx} id={navHeader} className="feature-nav-section">
            <div className="centered-small-input-area">
              <h3>{navHeader}</h3>
              {text}
            </div>
            {imageKey ? (
              <MultiImageSlider
                images={images[imageKey]}
                imageDisplayTime={4500 + (idx % 3) * 250}
                ratio={2 / 3}
              />
            ) : null}
          </section>
        ))}
      </div>
      <section id={finalSection.navHeader} className="feature-nav-section">
        <div className="centered-small-input-area">
          <h3>{finalSection.navHeader}</h3>
          {finalSection.text}
        </div>
        {finalSection.imageKey ? (
          <MultiImageSlider
            images={images[finalSection.imageKey]}
            imageDisplayTime={5000}
            ratio={2 / 3}
          />
        ) : null}
      </section>
    </div>
  );
};

export default SectionalFeatures;
