import React from "react";
import IconRender from "../common/icons/iconRender";

const UmbrellaInfo = ({ info }) => {
  return (
    <div
      style={{
        backgroundColor: info.backgroundColor,
        color: info.color,
        padding: 5,
      }}
    >
      <h5 className="text-center">{info.header}</h5>
      {info.text
        .split("\n")
        .map((t, i) =>
          t ? <div key={i}>{t}</div> : <div key={i}>&nbsp;</div>
        )}
      {info.webAddress ? (
        <p className="text-center">
          <a
            style={{
              backgroundColor: info.backgroundColor,
              color: info.color,
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={info.webAddress}
          >
            {info.webAddress}&nbsp;&nbsp;
            <IconRender name="externallink" />
          </a>
        </p>
      ) : null}
    </div>
  );
};

export default UmbrellaInfo;
