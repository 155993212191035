import React from "react";
import allowables from "../../utils/allowables";
import EventImage from "../assignedMatch/eventImage";
import { renderFlexSection, renderStrikeout } from "../../utils/textUtils";
import IconRender from "./../common/icons/iconRender";
import Tooltip from "./../common/tooltip/tooltip";
import HorizontalButtons from "./../common/dataSorting/horizontalButtons";
import TeamLogo from "../common/pageComponents/teamLogo";

const LeaderboardCardView = ({
  leaderboard,
  sport,
  selectedDiamondBoard,
  onSelectDiamondBoard,
  onSelectPlayer,
}) => {
  const renderInfo = (player) =>
    renderFlexSection(
      [25, 25, 50],
      [
        <EventImage player={player} />,
        <TeamLogo team={player.teamID} size={40} />,
        <div>
          <div className="cardview-text">
            <b>{allowables.splitName(player.name)}</b>
          </div>
          <div className="cardview-text">{player.teamName}</div>
        </div>,
      ]
    );

  const playerSection = {
    soccer: (player) => {
      return (
        <div>
          {renderInfo(player)}
          {renderStats[sport](player.events)}
        </div>
      );
    },
    football: (player) => {
      return (
        <div>
          {renderInfo(player)}
          {renderStats.football({
            ...player.events,
            fieldGoals: Object.keys(player.events).reduce(
              (acc, key) =>
                acc + (key.includes("Field Goal") ? player.events[key] : 0),
              0
            ),
            extraPoints: Object.keys(player.events).reduce(
              (acc, key) =>
                acc + (key.includes("Extra Point") ? player.events[key] : 0),
              0
            ),
            defense: Object.keys(player.events).reduce(
              (acc, key) =>
                acc + (key.includes("Defense") ? player.events[key] : 0),
              0
            ),
          })}
        </div>
      );
    },
    "diamond sports": (player) => {
      return (
        <div>
          {renderInfo(player)}
          {renderStats["diamond" + selectedDiamondBoard](player)}
        </div>
      );
    },
  };

  const renderStat = (stat, iconName, description, iconClass, header) => (
    <Tooltip content={description} isNull={!description}>
      {iconName ? (
        <div>
          <IconRender name={iconName} className={iconClass || "icon-raised"} />
          &nbsp;{stat}
        </div>
      ) : header ? (
        <b>
          {header}&nbsp;{stat}
        </b>
      ) : (
        stat
      )}
    </Tooltip>
  );

  const renderStats = {
    soccer: (stats) => {
      return (
        <div className="cardview-text">
          {renderFlexSection(
            [25, 25, 25, 25],
            [
              renderStat(stats.Goals, "soccer", "Goals"),
              renderStat(stats.Assists, "kick", "Assists"),
              renderStat(
                stats.YellowCards,
                "square",
                "Yellow Cards",
                "soccer-yellow-card-text"
              ),
              renderStat(
                stats.RedCards,
                "square",
                "Red Cards",
                "soccer-red-card-text"
              ),
            ],
            [],
            { paddingLeft: 15 }
          )}
        </div>
      );
    },
    football: (stats) => {
      return (
        <div className="cardview-text">
          {renderFlexSection(
            [25, 25, 25, 25],
            [
              renderStat(stats.touchdowns, "football", "Touchdowns"),
              renderStat(stats.fieldGoals, "kick", "Field Goals"),
              renderStat(stats.defense, "helmet", "Defensive Plays"),
              renderStat(
                stats["Penalty-penalty"] || 0,
                "flag",
                "Penalties",
                "soccer-yellow-card-text"
              ),
            ],
            [],
            { paddingLeft: 15 }
          )}
        </div>
      );
    },
    diamondbatting: (stats) => {
      return (
        <div className="cardview-text">
          {renderFlexSection(
            [34, 33, 33],
            [
              renderStat(stats.atBats, "diamond", "Home Runs"),
              renderStat(stats.rbis, "baseball", "Runs Batted In"),
              renderStat(
                Number(stats.battingAverage)?.toFixed(3),
                "baseballbat",
                "Batting Average"
              ),
            ],
            [],
            { paddingLeft: 15 }
          )}
        </div>
      );
    },
    diamondpitching: (stats) => {
      return (
        <div className="cardview-text">
          {renderFlexSection(
            [35, 25, 40],
            [
              renderStat(
                Number(stats.inningsPitched)?.toFixed(1),
                "throw",
                "Innings Pitched"
              ),
              renderStat(renderStrikeout(stats.strikeouts), "", "Strikeouts"),
              renderStat(
                stats.era === null ? (
                  <span>&infin;</span>
                ) : (
                  Number(stats.era)?.toFixed(2)
                ),
                "baseball",
                "Earned Run Average"
              ),
            ],
            [],
            { paddingLeft: 15 }
          )}
        </div>
      );
    },
  };

  return (
    <div className="cardview">
      {sport.includes("diamond") ? (
        <div>
          <HorizontalButtons
            buttons={["batting", "pitching"]}
            onClick={onSelectDiamondBoard}
            selectedType={selectedDiamondBoard}
          />
          {leaderboard[selectedDiamondBoard].map((player, idx) => (
            <div
              className="cardview-card"
              key={idx}
              onClick={() => onSelectPlayer(player, "leaderboard")}
            >
              {playerSection[sport](player)}
            </div>
          ))}
        </div>
      ) : (
        leaderboard.map((player, idx) => (
          <div
            key={idx}
            className="cardview-card clickable"
            onClick={() => onSelectPlayer(player, "leaderboard")}
          >
            {playerSection[sport](player)}
          </div>
        ))
      )}
    </div>
  );
};

export default LeaderboardCardView;
