import React from "react";
import IconRender from "../../common/icons/iconRender";
import TeamLogo from "../../common/pageComponents/teamLogo";

const PenaltyVisualization = ({ match, teamOrder, visualization }) => {
  if (
    !visualization ||
    (!visualization.home?.length && !visualization.away?.length)
  )
    return null;
  return (
    <div className="d-flex justify-content-center">
      <table className="box-score-table">
        <tbody>
          {teamOrder.map((team) => (
            <tr key={team} className="box-score-body">
              <td className="box-score-body">
                <TeamLogo
                  team={match[team + "TeamLogo"]}
                  name={match[team + "TeamName"]}
                  size={25}
                />
              </td>
              <td className="box-score-body text-left">
                {match[team + "TeamAbbreviation"]}
              </td>
              {visualization[team].map((line, idx) => (
                <td
                  key={idx}
                  className="box-score-inning text-center"
                  data-testid={
                    team + "-penalty-vis-" + (line ? "scored" : "missed")
                  }
                >
                  <IconRender
                    className="icon-mobile"
                    name={line ? "soccer" : "circle"}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PenaltyVisualization;
